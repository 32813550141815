$theme-colors: (
  "primary": #00838F,
  "danger": #ff4136
);

@import url("https://fonts.googleapis.com/css?family=Work+Sans:300");

$font-family-sans-serif: "Work Sans", sans-serif;
$font-weight-normal: 300;

@import "node_modules/bootstrap/scss/bootstrap";
